<template>
    <div class="p-8 text-xs">
        <div class="text-xs rounded-2xl">
            <div class="text-xs rounded-2xl">
                <div class="rounded-2xl p-5 grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <!-- <div class="bg-green-100 p-4 rounded-lg shadow-md text-center relative">
                        <div class="flex items-center justify-center mb-2">
                            <div class="h-12 w-12 bg-green-200 rounded-full flex items-center justify-center">
                                <i class="fas fa-ticket-alt text-green-600 text-2xl"></i>
                            </div>
                        </div>
                        <h3 class="font-bold text-lg">OPEN TIKET</h3>
                        <p class="text-2xl">{{ counts.open }}</p>

                        <button @click="refreshData" :disabled="isLoading"
                            class="absolute top-2 right-2 text-green-600 hover:text-green-800">
                            <i :class="isLoading ? 'fas fa-sync fa-spin' : 'fas fa-sync'"></i>
                        </button>

                        <button @click="openDetailModal"
                            class="mt-2 bg-green-600 text-white px-3 py-1 rounded-md hover:bg-green-700">
                            <i class="fas fa-eye mr-1"></i> 
                            Detail
                        </button>
                    </div> -->
                    <div class="w-full bg-white rounded-lg shadow-lg p-4 max-w-xs mx-auto">
                        <div class="flex items-center justify-between mb-4">
                            <div class="flex items-center space-x-2">
                                <span class="text-blue-100 text-blue-800 rounded-full p-2">
                                    <i class="fas fa-ticket-alt"></i>
                                </span>
                                <h2 class="font-bold text-lg">Status Tiket</h2>
                            </div>
                            <!-- <p class="text-gray-500 text-xs">Tiket</p> -->
                            <button @click="refreshData" class="mt-2 text-blue px-3rounded-md">
                                <i class="fas fa-sync mr-1"></i>
                            </button>

                        </div>
                        <!-- <h3 class="font-bold text-lg">SOLVED TIKET</h3>
                        <p class="text-2xl">{{ counts.solved }}</p> -->
                        <div
                            class="flex justify-between items-center border-b pb-2 hover:bg-gray-100 transition-colors">
                            <h3 class="font-bold text-lg ml-2">Open Tiket</h3>
                            <p class="text-lg">{{ counts.open }}</p>
                            <!-- You can add more elements here if needed -->
                        </div>
                        <div
                            class="flex justify-between items-center border-b pb-2 hover:bg-gray-100 transition-colors">
                            <h3 class="font-bold text-lg ml-2">Close Tiket</h3>
                            <p class="text-lg">{{ counts.close }}</p>
                            <!-- You can add more elements here if needed -->
                        </div>

                        <!-- Graph Representation (as an image placeholder for simplicity) -->
                        <!-- <div class="mt-4 bg-blue-100 rounded-lg h-16 flex items-center justify-center">
                            <p class="text-blue-500 text-xs">Graph Placeholder</p>
                        </div> -->
                        <apex-chart type="pie" :options="statustiketOptions" :series="statustiketData"></apex-chart>

                        <!-- Detail Button -->
                        <!-- <button class="bg-blue-600 text-white font-bold py-2 w-full rounded-lg mt-4">Detail</button> -->
                        <button @click="openDetailModal"
                            class="bg-blue-600 text-white font-bold py-2 w-full rounded-lg mt-4">
                            <i class="fas fa-eye mr-1"></i> 
                            Detail
                        </button>
                    </div>

                    <transition name="modal">
                        <div v-if="isDetailModalOpen" @click.self="closeDetailModal"
                            class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl relative">
                                <button @click="closeDetailModal"
                                    class="absolute top-2 right-2 text-gray-500 hover:text-gray-800">
                                    <i class="fas fa-times"></i>
                                </button>
                                <h3 class="font-bold text-lg mb-4">Open Ticket Details</h3>
                                <div>
                                    <table class="min-w-full divide-y divide-gray-200">
                                        <thead>
                                            <tr>
                                                <th class="px-4 py-2 border-b">ID Tiket</th>
                                                <th class="px-4 py-2 border-b">Keluhan</th>
                                                <th class="px-4 py-2 border-b">Customer</th>
                                                <th class="px-4 py-2 border-b">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in data" :key="item.id">
                                                <td class="px-4 py-2 border-b">{{ item.tiket_id }}</td>
                                                <td class="px-4 py-2 border-b">{{ item.description }}</td>
                                                <td class="px-4 py-2 border-b">{{ item.Customer }}</td>
                                                <td class="px-4 py-2 border-b">
                                                    <span :class="getStatusBadge(item.status_name)">
                                                        {{ item.status_name }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="mt-4">
                                    <div class="flex justify-between items-center">
                                        <button @click="closeDetailModal"
                                            class="mt-4 bg-red-600 text-white px-3 py-1 rounded-md hover:bg-red-700">
                                            Close
                                        </button>
                                        <div class="flex items-center">
                                            <span>Page {{ currentDetailPage }} - {{ totalDetailPages }} of {{ totalRows
                                                }}</span>
                                            <unicon name="angle-left" class="cursor-pointer"
                                                :fill="currentDetailPage > 1 ? '#000000' : '#A1A1AA'"
                                                @click="currentDetailPage > 1 && (currentDetailPage--)"></unicon>
                                            <unicon name="angle-right" class="cursor-pointer"
                                                :fill="currentDetailPage < totalDetailPages ? '#000000' : '#A1A1AA'"
                                                @click="currentDetailPage < totalDetailPages && (currentDetailPage++)">
                                            </unicon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                    <div class="w-full bg-white rounded-lg shadow-lg p-4 max-w-xs mx-auto">
                        <div class="flex items-center justify-between mb-4">
                            <div class="flex items-center space-x-2">
                                <span class="text-blue-100 text-blue-800 rounded-full p-2">
                                    <i class="fas fa-spinner text-orange-600 text-sm"></i>
                                </span>
                                <h2 class="font-bold text-lg">Status Progress</h2>
                            </div>
                            <!-- <p class="text-gray-500 text-xs">Tiket</p> -->
                            <button @click="refreshData" class="mt-2 text-blue px-3rounded-md">
                                <i class="fas fa-sync mr-1"></i>
                            </button>
                        </div>
                        <!-- <h3 class="font-bold text-lg">SOLVED TIKET</h3>
                        <p class="text-2xl">{{ counts.solved }}</p> -->
                        <div
                            class="flex justify-between items-center border-b pb-2 hover:bg-gray-100 transition-colors">
                            <h3 class="font-bold text-lg ml-2">On Progress</h3>
                            <p class="text-lg">{{ counts.onProgress }}</p>
                            <!-- You can add more elements here if needed -->
                        </div>
                        <div
                            class="flex justify-between items-center border-b pb-2 hover:bg-gray-100 transition-colors">
                            <h3 class="font-bold text-lg ml-2">Solved</h3>
                            <p class="text-lg">{{ counts.solved }}</p>
                            <!-- You can add more elements here if needed -->
                        </div>

                        <!-- Graph Representation (as an image placeholder for simplicity) -->
                        <!-- <div class="mt-4 bg-blue-100 rounded-lg h-16 flex items-center justify-center">
                            <p class="text-blue-500 text-xs">Graph Placeholder</p>
                        </div> -->
                        <apex-chart type="donut" :options="statusOptions" :series="statusData"></apex-chart>

                        <!-- Detail Button -->
                        <button class="bg-blue-600 text-white font-bold py-2 w-full rounded-lg mt-4">Detail</button>
                    </div>
                    <div class="w-full bg-white rounded-lg shadow-lg p-4 max-w-xs mx-auto">
                        <div class="w-full flex items-center justify-between mb-4">
                            <div class="w-full flex items-center space-x-2">
                                <span class="text-blue-100 text-blue-800 rounded-full p-2">
                                    <i class="fas fa-check-circle"></i>
                                </span>
                                <h2 class="font-bold text-lg">Status Prioritas</h2>
                            </div>
                            <!-- <p class="text-gray-500 text-xs">Tiket</p> -->
                            <button @click="refreshData" class="mt-2 text-blue px-3rounded-md">
                                <i class="fas fa-sync mr-1"></i>
                            </button>
                        </div>
                        <!-- <h3 class="font-bold text-lg">SOLVED TIKET</h3>
                        <p class="text-2xl">{{ counts.solved }}</p> -->
                        <div
                            class="flex justify-between items-center border-b pb-2 hover:bg-gray-100 transition-colors">
                            <h3 class="font-bold text-lg ml-2">High</h3>
                            <p class="text-lg">{{ counts.high }}</p>
                            <!-- You can add more elements here if needed -->
                        </div>
                        <div
                            class="flex justify-between items-center border-b pb-2 hover:bg-gray-100 transition-colors">
                            <h3 class="font-bold text-lg ml-2">Low</h3>
                            <p class="text-lg">{{ counts.low }}</p>
                            <!-- You can add more elements here if needed -->
                        </div>

                        <!-- Graph Representation (as an image placeholder for simplicity) -->
                        <!-- <div class="mt-4 bg-blue-100 rounded-lg h-16 flex items-center justify-center">
                            <p class="text-blue-500 text-xs">Graph Placeholder</p>
                        </div> -->
                        <apex-chart type="donut" width="255"  :options="priorityOptions" :series="priorityData"></apex-chart>

                        <!-- Detail Button -->
                        <button class="bg-blue-600 text-white font-bold py-2 w-full rounded-lg mt-4">Detail</button>
                    </div>


                </div>
            </div>

            <!-- <div class="mb-5 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div class="bg-white p-4 rounded-lg shadow-md">
                    <h3 class="font-bold text-lg text-center">Priority Distribution</h3>
                    <div class="flex justify-center mt-4">
                        <apex-chart type="pie" :options="priorityOptions" :series="priorityData"></apex-chart>
                    </div>
                </div>

                <div class="bg-white p-4 rounded-lg shadow-md">
                    <h3 class="font-bold text-lg text-center">Status Progress</h3>
                    <div class="flex justify-center mt-4">
                        <apex-chart type="donut" :options="statusOptions" :series="statusData"></apex-chart>
                    </div>
                </div>
            </div> -->

        </div>
        <div class="rounded-2xl bg-white p-8">
            <div>
                <div class="flex flex-wrap items-center content-between justify-between">
                    <span class="font-semibold text-base">Data Tiket</span>
                    <div class="flex">
                        <div class="text-xs relative">
                            <div class="absolute top-2.5 left-2.5">
                                <unicon name="search" width="14.34px" height="14.33px" fill="#A1A1AA"></unicon>
                            </div>
                            <input v-model="keyword" type="text"
                                class="block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs"
                                name="keyword" ref="keyword" id="keyword" @keyup.enter="searchData()"
                                placeholder="Pencarian" />
                        </div>
                        <div>
                            <button class="text-white px-4 py-2 bg-blue-500 rounded ml-4"
                                @click="$router.push('/tiket/add')">Tambah</button>
                        </div>
                    </div>
                </div>
                <div class="mt-4 w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                                <td>Tiket ID</td>
                                <td class="py-4">Judul Keluhan</td>
                                <td>Nama Pelanggan</td>
                                <td>Prioritas</td>
                                <td>Status</td>
                                <td>Status Pengerjaan</td>
                                <td>Tiket Dibuat</td>
                                <td>Tiket Update</td>
                                <td>Aksi</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item.id"
                                class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                <td>
                                    <span v-if="item.tiket_id">
                                        <!-- Clickable tiket_id if it exists, with blue color -->
                                        <router-link :to="'/tiket/detail/' + item.id"
                                            class="text-blue-600 hover:underline">
                                            {{ item.tiket_id }}
                                        </router-link>
                                    </span>
                                    <span v-else class="text-black">
                                        <!-- Non-clickable N/A with black color -->
                                        N/A
                                    </span>
                                </td>
                                <td class="py-4">
                                    <div class="flex items-center">
                                        <div>
                                            <p>{{ item.name }}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>{{ item.Customer }}</span>
                                </td>
                                <td>
                                    <span :class="getPriorityBadge(item.priority_name)">
                                        {{ item.priority_name }}
                                    </span>
                                </td>
                                <td>
                                    <!-- Status Badge for status_name -->
                                    <span :class="getStatusBadge(item.status_name)">
                                        {{ item.status_name }}
                                    </span>
                                </td>
                                <td>
                                    <!-- Status Process Badge for status_process_name -->
                                    <span :class="getProcessStatusBadge(item.status_process_name)">
                                        {{ item.status_process_name }}
                                    </span>
                                </td>
                                <td>
                                    <span>{{ item.createdAt }}</span>
                                </td>
                                <td>
                                    <span>{{ item.updatedAt }}</span>
                                </td>
                                <td>
                                    <div class="relative inline-block text-left">
                                        <button @click="toggleDropdown(item.id)" type="button"
                                            class="inline-flex items-center px-2 py-2 text-sm font-semibold text-white bg-gray-600 rounded-md hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                            <i class="fa fa-cog px-1"></i> Aksi
                                        </button>

                                        <!-- Dropdown menu -->
                                        <div v-if="isDropdownOpen(item.id)"
                                            class="absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div class="py-1">
                                                <!-- Ticket Detail Button -->
                                                <button v-if="item.status_name.toLowerCase() !== 'closed'"
                                                    @click="$router.push('tiket/detail/' + item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-ticket px-2"></i> Ticket
                                                </button>
                                                <!-- Edit Button -->
                                                <button v-if="role_id !== 35"
                                                    @click="$router.push('tiket/edit/' + item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-edit px-2"></i> Edit
                                                </button>
                                                <!-- Change Priority -->
                                                <button @click="showPriorityModal(item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-flag px-2"></i> Ubah Prioritas
                                                </button>

                                                <!-- Change Status -->
                                                <button @click="openStatusModal(item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-exchange px-2"></i> Ubah Status
                                                </button>

                                                <!-- Change Progress -->

                                                <button @click="openProcessModal(item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-spinner px-2"></i> Ubah Proses
                                                </button>


                                                <!-- Delete Button (Hidden for role_id 35) -->
                                                <button v-if="role_id !== 35" @click="openDelete(item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-red-700 hover:bg-gray-100">
                                                    <i class="fa fa-trash px-2"></i> Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="flex justify-end items-center mt-4">
                        <div class="flex items-center">
                            <div class="font-medium">
                                <span>{{ startNumber }}-{{ endNumber }} of {{ totalRows }}</span>
                            </div>
                            <div class="flex">
                                <unicon name="angle-left" class="cursor-pointer"
                                    :fill="currentPage > 1 ? '#00000' : '#A1A1AA'" @click="previousPage()"></unicon>
                                <unicon name="angle-right" class="cursor-pointer"
                                    :fill="totalRows == endNumber ? '#A1A1AA' : '#00000'" @click="nextPage()"></unicon>
                            </div>
                        </div>
                    </div>
                </div>
                <deleteComponent v-on:closeModal="closeModal" v-on:deleteUser="deleteData()" v-if="modal_dialog" />

                <transition name="modal-fade">
                    <div v-if="isPriorityModalOpen"
                        class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                        @click.self="closePriorityModal">
                        <div class="bg-white rounded-lg p-6 w-96 relative">
                            <button @click="closePriorityModal" class="absolute top-2 right-2 text-gray-500">
                                <i class="fas fa-times"></i>
                            </button>
                            <h2 class="text-xl font-bold mb-4">Update Prioritas Tiket</h2>
                            <div class="mb-4">
                                <label class="block text-gray-700">Pilih Prioritas Tiket:</label>
                                <select v-model="priority_id" class="w-full px-4 py-2 border rounded">
                                    <option value="" disabled selected>Silahkan Pilih Prioritas</option>
                                    <option value="1">Low</option>
                                    <option value="2">Medium</option>
                                    <option value="3">High</option>
                                    <option value="4">Critical</option>
                                </select>
                            </div>

                            <div class="flex justify-end space-x-2">
                                <button @click="closePriorityModal"
                                    class="px-4 py-2 bg-gray-300 rounded">Cancel</button>
                                <button @click="updatePriorityTicket"
                                    class="px-4 py-2 bg-blue-500 text-white rounded">Update</button>
                            </div>
                        </div>
                    </div>
                </transition>

                <!-- modal Status Tiket Updated -->
                <transition name="modal-fade">
                    <div v-if="isStatusModalOpen"
                        class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                        @click.self="closeStatusModal">
                        <div class="bg-white rounded-lg p-6 w-96 relative">
                            <button @click="closeStatusModal" class="absolute top-2 right-2 text-gray-500">
                                <i class="fas fa-times"></i>
                            </button>
                            <h2 class="text-xl font-bold mb-4">Update Status Tiket</h2>
                            <div class="mb-4">
                                <label class="block text-gray-700">Select Status Tiket:</label>
                                <select v-model="ticket_statuses_id" class="w-full px-4 py-2 border rounded">
                                    <option value="" disabled selected>Silahkan Pilih Status Tiket</option>
                                    <option value="1">Open</option>
                                    <option value="2">Close</option>
                                </select>
                            </div>
                            <div class="flex justify-end space-x-2">
                                <button @click="closeStatusModal" class="px-4 py-2 bg-gray-300 rounded">Cancel</button>
                                <button @click="updateStatusTicket"
                                    class="px-4 py-2 bg-blue-500 text-white rounded">Update</button>
                            </div>
                        </div>
                    </div>
                </transition>

                <!-- modal Status Process Updated -->
                <transition name="modal-fade">
                    <div v-if="isProcessModalOpen"
                        class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                        @click.self="closeProcessModal">
                        <div class="bg-white rounded-lg p-6 w-96 relative">
                            <button @click="closeProcessModal" class="absolute top-2 right-2 text-gray-500">
                                <i class="fas fa-times"></i>
                            </button>
                            <h2 class="text-xl font-bold mb-4">Update Status Proses</h2>
                            <div class="mb-4">
                                <label class="block text-gray-700">Select Proses Status:</label>
                                <select v-model="status_process_id" class="w-full px-4 py-2 border rounded">
                                    <option value="" disabled selected>Silahkan Pilih Status Proses</option>
                                    <option value="1">On Progress</option>
                                    <option value="2">Solved</option>
                                    <option value="3">Pending</option>
                                    <option value="4">Hold</option>
                                    <option value="5">Assigned</option>
                                </select>
                            </div>
                            <div class="flex justify-end space-x-2">
                                <button @click="closeProcessModal" class="px-4 py-2 bg-gray-300 rounded">Cancel</button>
                                <button @click="updateProcessStatus"
                                    class="px-4 py-2 bg-blue-500 text-white rounded">Update</button>
                            </div>
                        </div>
                    </div>
                </transition>


            </div>
        </div>
    </div>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueApexCharts from 'vue-apexcharts';
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global';
import deleteComponent from '../../components/global/deleteComponent.vue';
import priorityComponent from '../../components/global/prioritystatusComponent.vue';
import statusComponent from '../../components/global/tiketstatusComponent.vue';
import processComponent from '../../components/global/process_statusComponent.vue';

export default {
    data: () => ({
        data: [],
        totalRows: 1,
        perPage: 10,
        limit: 8,
        startNumber: 1,
        endNumber: 8,
        currentPage: 1,
        keyword: '',
        status_process_name: '', // Pastikan diinisialisasi dengan nilai default
        // ticketData: {
        //     id:'',
        //     status_process_id:'',
        // },
        ticket_statuses_id: null,
        priority_id: null, // New data property for priority level
        selectedTicketId: null, // Ensure this is defined
        modal_dialog: false, // delete modal
        isPriorityModalOpen: false, // Add this property to manage modal visibility
        isStatusModalOpen: false,
        isProcessModalOpen: false,
        showStatusModal: false,
        showProcessModal: false,
        delete_id: '',
        default_url: process.env.VUE_APP_IMAGES,
        openDropdownId: null,
        isLoading: false, // To manage loading state
        isDetailModalOpen: false, // To manage detail modal visibility
        selectedTicketDetails: [], // To hold the details of the open tickets
        counts: {
            open: 0,
            close: 0,
            onProgress: 0,
            solved: 0,
            low: 0,
            medium: 0,
            high: 0,
            critical: 0,
        },
        priorityOptions: {
            chart: {
                type: 'pie',
            },
            labels: ['Low', 'High'],
            colors: ['#00a8ff', '#9c88ff'], // Distinct colors for each
        },
        priorityData: [0, 4, 15, 1, 0], // Default values
        statusOptions: {
            chart: {
                type: 'donut',
            },
            labels: ['On Progress', 'Solved'],
            colors: ['#1E90FF', '#FFA500', '#28a745'],
        },
        statusData: [15, 25, 35], // Replace with actual data
        statustiketOptions: {
            chart: {
                type: 'donut',
            },
            labels: ['Open Ticket', 'Close Ticket'],
            colors: ['#1E90FF', '#FFA500', '#28a745'],
        },
        statustiketData: [15, 25, 35], // Replace with actual data
    }),
    mixins: [globalMixin],

    methods: {
        ...mapActions([]),
        getData() {
            this.axios.get('v1/tiket?page=' + this.currentPage + `&limit=${this.limit}&keyword=${this.keyword}`, this.config)
                .then((ress) => {
                    this.data = ress.data.rows;
                    this.totalRows = ress.data.count;
                    console.log(ress.data.counts);

                    // Update counts and priorityData
                    if (ress.data.counts) {
                        this.counts.open = ress.data.counts.open;
                        this.counts.close = ress.data.counts.close;
                        // console.log('Open count:', this.counts.open);
                        this.counts.onProgress = ress.data.counts.onProgress;
                        this.counts.solved = ress.data.counts.solved;
                        this.counts.low = ress.data.counts.low;
                        this.counts.medium = ress.data.counts.medium;
                        this.counts.high = ress.data.counts.high;
                        this.counts.critical = ress.data.counts.critical;
                    } else {
                        console.warn('status_counts is undefined');
                    }
                    this.statusData = [
                        ress.data.counts.onProgress,
                        ress.data.counts.solved,
                    ];

                    this.statustiketData = [
                        ress.data.counts.open,
                        ress.data.counts.close,
                    ];

                    this.priorityData = [
                        ress.data.counts.low,
                        ress.data.counts.high
                    ];
                })
                .catch((err) => {
                    this.errorResponse(err);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        selectTicket(ticket) {
            this.selectedTicketId = ticket.id; // Set the selected ticket ID
            this.status_process_id = ticket.status_process_id; // Optional: set the process status if needed
        },
        async fetchTicketData() {
            try {
                const response = await axios.get(`v1/tiket/${this.$route.params.id}`);
                this.ticketData = response.data;
                this.selectedTicketId = response.data.id; // Set the selected ticket ID
            } catch (error) {
                this.$snack.error({
                    text: 'Failed to fetch ticket data'
                });
            }
        },
        async updateProcessStatus() {
            let formData = new FormData();
            formData.append('status_process_id', this.status_process_id);
            formData.append('ticket_statuses_id', this.status_process_id);

            // Otomatis update ticket_statuses_id ke 'close' jika status_process_id = 2 (Solve)
            if (this.status_process_id === 2) {
                formData.append('ticket_statuses_id', 2); // 2 = Close
            }

            // Tambahkan kondisi untuk memastikan jika status_process_id = 1, tetap bernilai 1
            if (this.status_process_id === 1) {
                formData.set('status_process_id', 1); // Tetap set status_process_id ke 1
                formData.set('ticket_statuses_id', 1); // Tetap set ticket_statuses_id ke 1
            }

            try {
                // Update status proses
                await axios.put(`v1/tiket/ubahStatusProses/${this.selectedTicketId}`, formData, this.config);
                this.$snack.success({
                    text: 'Status proses tiket diperbarui!'
                });
                this.moveTicketToTop(this.selectedTicketId); // Pindahkan tiket ke posisi paling atas
                this.closeProcessModal(); // Pastikan ini menutup modal proses
                this.refreshData(); // Refresh data setelah update

                // Update status tiket
                await axios.put(`v1/tiket/ubahStatusTiket/${this.selectedTicketId}`, formData, this.config);
                // this.$snack.success({
                //     text: 'Status tiket berhasil diperbarui!'
                // });
                this.moveTicketToTop(this.selectedTicketId); // Pindahkan tiket ke posisi paling atas
                this.closeStatusModal(); // Pastikan ini menutup modal status
                this.refreshData(); // Refresh data setelah update

            } catch (error) {
                if (error.response && error.response.status === 400) {
                    this.$refs.form.setErrors(error.response.data.message);
                }
                this.$snack.error({
                    text: 'Gagal memperbarui status proses tiket'
                });
            }
        },
        async updateStatusTicket() {
            if (!this.selectedTicketId) {
                console.error('selectedTicketId is not set');
                this.$snack.error({
                    text: 'Cannot update: No ticket ID specified'
                });
                return; // Exit early
            }

            let formData = new FormData();
            formData.append('ticket_statuses_id', this.ticket_statuses_id); // Append selected status

            try {
                const response = await axios.put(`v1/tiket/ubahStatusTiket/${this.selectedTicketId}`, formData, this.config);
                this.$snack.success({
                    text: 'Status tiket berhasil diperbarui!'
                });
                this.moveTicketToTop(this.selectedTicketId); // Pindahkan tiket ke posisi paling atas
                this.closeStatusModal(); // Pastikan ini menutup modal status, bukan modal proses
                this.refreshData(); // Refresh the data after update
            } catch (error) {
                console.error("Error response:", error);
                if (error.response && error.response.status === 400) {
                    this.$refs.form.setErrors(error.response.data.message);
                }
                this.$snack.error({
                    text: 'Gagal memperbarui status tiket'
                });
            }
        },
        async updatePriorityTicket() {
            if (!this.selectedTicketId) {
                console.error('selectedTicketId is not set');
                this.$snack.error({
                    text: 'Cannot update: No ticket ID specified'
                });
                return; // Exit early
            }

            let formData = new FormData();
            formData.append('priority_id', this.priority_id); // Append selected priority level

            try {
                const response = await axios.put(`v1/tiket/ubahStatusPriority/${this.selectedTicketId}`, formData, this.config);
                this.$snack.success({
                    text: 'Prioritas tiket berhasil diperbarui!'
                });
                this.moveTicketToTop(this.selectedTicketId); // Pindahkan tiket ke posisi paling atas
                this.closePriorityModal(); // Close the modal after update
                this.refreshData(); // Refresh the data after update
            } catch (error) {
                console.error("Error response:", error);
                if (error.response && error.response.status === 400) {
                    this.$refs.form.setErrors(error.response.data.message);
                }
                this.$snack.error({
                    text: 'Gagal memperbarui prioritas tiket'
                });
            }
        },
        refreshData() {
            this.isLoading = true;
            this.getData();
            setTimeout(() => {
                this.isLoading = false; // Simulating loading time, remove or adjust based on actual data load
            }, 1000);
        },
        openDetailModal() {
            // console.log(this.data.filter());
            this.currentDetailPage = 1; // Reset to the first page when opening the modal
            console.log(this.isDetailModalOpen);
            this.isDetailModalOpen = true;
        },
        closeDetailModal() {
            this.isDetailModalOpen = false;
            this.selectedTicketDetails = [];
        },
        openDelete(id) {
            this.delete_id = id;
            this.closeModal();
        },
        showPriorityModal(id) {
            this.selectedTicketId = id;
            this.priority_level = null; // Reset priority when opening the modal
            this.isPriorityModalOpen = true;
        },
        openStatusModal(id) {
            this.selectedTicketId = id; // Set the selected ticket ID
            this.ticket_statuses_id = null; // Reset status when opening the modal
            this.isStatusModalOpen = true; // Show the modal
        },
        openProcessModal(id) {
            this.selectedTicketId = id; // Set the selected ticket ID
            this.status_process_id = null; // Reset status when opening the modal
            this.ticket_statuses_id = null
            this.isProcessModalOpen = true; // Show the modal
            console.log("Selected Ticket ID:", this.selectedTicketId); // Tambahkan log ini untuk debug
        },
        closePriorityModal() {
            this.isPriorityModalOpen = false;
        },
        closeProcessModal() {
            this.isProcessModalOpen = false;
        },
        closeStatusModal() {
            this.isStatusModalOpen = false;
        },
        closeDropdown() {
            this.openDropdownId = false;
        },
        deleteData() {
            this.axios.delete('v1/tiket/' + this.delete_id, this.config)
                .then((ress) => {
                    this.closeModal();
                    this.getData();
                    this.success({
                        text: ress.data.message,
                    });
                });
        },
        ubahStatus() {
            this.axios.put('v1/tiket/' + this.$route.params.id, data, this.config)
                .then((ress) => {
                    this.closeStatusModal();
                    this.getData();
                    this.$snack.success({
                        text: ress.data.message,
                    });
                });
        },
        ubahProcess() {
            this.axios.put('v1/tiket/' + this.$route.params.id, data, this.config)
                .then((ress) => {
                    this.closeProcessModal();
                    this.getData();
                    this.$snack.success({
                        text: ress.data.message,
                    });
                });
        },
        closeModal() {
            this.modal_dialog = !this.modal_dialog;
        },
        getStatusBadge(status) {
            switch (status.toLowerCase()) {
                case 'open':
                    return 'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10';
                case 'closed':
                    return 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10';
                default:
                    return '';
            }
        },
        toggleDropdown(id) {
            this.openDropdownId = this.openDropdownId === id ? null : id;
        },
        isDropdownOpen(id) {
            return this.openDropdownId === id;
        },
        handleClickOutside(event) {
            // Jika dropdown terbuka dan klik di luar elemen dropdown, tutup dropdown
            if (this.openDropdownId && !event.target.closest('.relative.inline-block.text-left')) {
                this.openDropdownId = null;
            }
        },
        moveTicketToTop(ticketId) {
            const ticketIndex = this.data.findIndex(ticket => ticket.id === ticketId);
            if (ticketIndex !== -1) {
                const [ticket] = this.data.splice(ticketIndex, 1); // Hapus tiket dari daftar
                this.data.unshift(ticket); // Tambahkan tiket ke posisi pertama
            }
        },
        getProcessStatusBadge(status) {
            switch (status.toLowerCase()) {
                case 'on progress':
                    return 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20';
                case 'solved':
                    return 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20';
                case 'pending':
                    return 'inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10';
                case 'hold':
                    return 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10';
                case 'assigned':
                    return 'inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10';
                default:
                    return '';
            }
        },
        getPriorityBadge(priority) {
            switch (priority.toLowerCase()) {
                case 'low':
                    return 'inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-800';
                case 'medium':
                    return 'inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800';
                case 'high':
                    return 'inline-flex items-center rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-800';
                case 'critical':
                    return 'inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-800';
                default:
                    return '';
            }
        }
    },
    mounted() {
        // Tambahkan event listener untuk klik di luar dropdown
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        // Hapus event listener ketika komponen di-destroy
        document.removeEventListener('click', this.handleClickOutside);
    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
            role_id: 'auth/role_id'
        }),
        role_id() {
            return this.$store.state.auth.user.role_id; // Directly access role_id from Vuex state
        },
    },
    created() {
        this.getData();
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        deleteComponent,
        apexChart: VueApexCharts,
        openStatusModal: statusComponent,
        openProcessModal: processComponent,
        openPriorityModal: priorityComponent,
    }
}
</script>
